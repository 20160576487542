import React, { Component } from "react";
import "./landingpage.css";
import LandingVideo from "../assets/videos/landingvideo.mp4";
import LandingLogo from "../assets/images/MainPageLogo.png";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import YouTube from 'react-youtube';


const srcvideo =
  "https://archive.org/download/BigBuckBunny_124/Content/big_buck_bunny_720p_surround.mp4";

const handleAnchorClick = event => {
  // 👇️ use event.preventDefault() if you want to
  // prevent navigation
  // event.preventDefault();

  console.log('Anchor element clicked');

  // 👇️ refers to the link element
  console.log(event.currentTarget);
};

const opts = {
  height: '390',
  width: '640',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
  },
};


		
       // <video autoPlay loop muted id="test" ref="vidRef" className="landing-video">
        //<source src={"https://www.youtube.com/embed/mmCzkS1CEJs"} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></source>
       //</video>

      // <video autoPlay loop muted id="test" ref="vidRef" className="landing-video">
      //<source src={srcvideo} type="video/mp4"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></source>
      // </video>

class LandingPage extends Component {



  render() {
    const { lpAnimation } = this.props;
    return (
      <div >
    

        <Container fluid className="video-background iframe">
          <Row style={{ height: "100vh" }}>
          <iframe 
          frameborder="0"
          height="100%"  
          width="100%"
          src="https://youtube.com/embed/8BWdBCA4S04?loop=1&autoplay=1&controls=1&showinfo=0&mute=1&playlist=8BWdBCA4S04"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full"
        > 
         
        </iframe>
            <Col>
              <div className="landing-center-data">


                <div className={"Landing-logo " + (lpAnimation ? "landing-animation-down" : "")}>
                  <img src={LandingLogo} />
                </div>
                
                <div className={"content " + (lpAnimation ? "landing-animation-up" : "")}>
                  <div className="description">


   
                    <p>

                       Fourthmotion: A biped controller enabled platformer where you collide with vehicles, jungle animals, and use physics 
                       to balance. Fast-paced and thrilling!

                  </p>
                  </div>
                 

                  {/*<button className="landing-btn">
                  <a href="https://transactions.sendowl.com/products/77952943/2F765C1A/purchase" rel="nofollow">
                    <img src="https://transactions.sendowl.com/assets/external/v2/buy-now.png" /></a>
                    <script type="text/javascript" src="https://transactions.sendowl.com/assets/sendowl.js" ></script>
                  </button>*/}


                <button className="landing-btn">
                  <a href="https://store.steampowered.com/app/1334290/FourthMotion/?beta=1" rel="nofollow">
                    Buy on  Steam
                   </a>
                
                </button>

                


               {/*  <button className="landing-btn">
                    Get Part 2
    </button> */}
                  <div className="description">
                    <p>
                       Fourthmotion is a groundbreaking platformer game that combines robotics science and fast-paced action.
                       As the central character, players must navigate through a  busy environment filled with heavy vehicular traffic,
                       including trains, trucks, buses, cars, and bikes, as well as unpredictable jungle animals. 
                       The excitement arises when collisions occur, and players must utilize the character's unique physics powers to
                       maintain balance and prevent themselves from falling into a deep valley below. With its innovative gameplay 
                       mechanics and challenging obstacles, Fourthmotion offers a thrilling and skill-based gaming experience.
                  </p>
                  </div>
                </div>
                <div className="Scroll-down-Arrow">
                  <span onClick={() => this.props.handlePageChange(1)}></span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default LandingPage;
