import React from 'react';
import './squarebox.css'

const SquareBoxes  = (props) =>{

    return(

        <div className="square-box-main" onClick={props.playVideoModal}>
            <div className="heading"><p>{props.heading}</p></div>
            <div className="details"><p>{props.details}</p></div>
        </div>

    )

}

export default SquareBoxes;