import React from 'react';
import Button from 'react-bootstrap/Button';
import './arrowlinks.css'


const ArrowLinks = (props) => {

    return (

        <div className={"arrow-links"}
            style={props.btnText == "Home" ? { marginBottom: '23px' } :
                props.btnText == "Trailer" ? { marginBottom: '23px', marginTop: "23px" } :
                    props.btnText == "ScreenShots" ? { marginTop: '40px', marginBottom: '43px' } :
                        props.btnText == "Mooding" ? { marginTop: '23px', marginBottom: '40px' } : { marginBottom: '0px' }}
        >
            <button
                style={props.btnText == "Home" ? { padding: '2px 10px 23px 10px' } :
                    props.btnText == "Trailer" ? { padding: '2px 10px 23px 10px' } :
                        props.btnText == "ScreenShots" ? { padding: '2px 10px 23px 10px' } : {}}
                            onClick={props.OnNavButtonClick} >{props.btnText}
            </button>

        </div>
    )
}

export default ArrowLinks;