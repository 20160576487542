import React, { Component } from "react";
import "./screenshots.css";
import Parallax from "parallax-js";
import SS1 from "../../assets/images/screenshot.jpg";
import SS2 from "../../assets/images/screenshot2.jpg";
import SS3 from "../../assets/images/screenshot3.jpg";

import { Carousel } from "react-bootstrap";

class Screenshots extends Component {
  componentDidMount() {
    let scene1 = this.refs.scene1;
    let parallaxInstance1 = new Parallax(scene1, {
      hoverOnly: true,
      limitY: 30,
      originX: 0,
      originY: 0,
    });

    let scene2 = this.refs.scene2;
    let parallaxInstance2 = new Parallax(scene2, {
      hoverOnly: true,
      limitY: 10,
      originX: 0,
      originY: 0,
    });

    let scene = this.refs.scene;
    let parallaxInstance = new Parallax(scene, {
      hoverOnly: true,
      limitY: 20,
      originX: 0,
      originY: 0,
    });
    // parallaxInstance.friction(0.2, 0.2);
  }

  render() {
    return (
      <div id="ScreenShots">
        {/* <div id="ScreenShots" className="Main-screenShots">
                <div ref="scene" className="scene">
                    <img src={SS1} className="ss" data-depth="10"></img>
                </div>

            </div> */}

        <Carousel controls="false ">
          <Carousel.Item>
            <div id="ScreenShots" className="Main-screenShots">
              <div ref="scene1" className="scene">
                <img src={SS1} className="ss" data-depth="10"></img>
              </div>
            </div>
            {/* <Carousel.Caption>
        <h3>First slide label</h3>
        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
    </Carousel.Caption> */}
          </Carousel.Item>
          <Carousel.Item>
            <div id="ScreenShots" className="Main-screenShots">
              <div ref="scene2" className="scene">
                <img src={SS2} className="ss" data-depth="10"></img>
              </div>
            </div>
            {/* <Carousel.Caption>
        <h3>Second slide label</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </Carousel.Caption> */}
          </Carousel.Item>
          <Carousel.Item>
            <div id="ScreenShots" className="Main-screenShots">
              <div ref="scene" className="scene">
                <img src={SS3} className="ss" data-depth="10"></img>
              </div>
            </div>

            {/* <Carousel.Caption>
        <h3>Third slide label</h3>
        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
    </Carousel.Caption> */}
          </Carousel.Item>
        </Carousel>
      </div>
    );
  }
}

export default Screenshots;
