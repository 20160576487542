import './App.css';
import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom'
import ReactFullpage from './pages/ReactFullPage'
import NavBar from "./components/navbar";
import SideNav from "./components/sidenav";

class App extends Component {
  state = {
    SideNavButtons: [
      { _id: 0, text: "Home", isActive: true },
      { _id: 1, text: "Trailer", isActive: false },
      { _id: 2, text: "ScreenShots", isActive: false },
      { _id: 3, text: "Mooding", isActive: false }
    ],
    mdAnim: false,
    ActiveClass: "dark",
    currentPage: null,
    showAnimation: false,
    lpAnimation: false,
  };
  componentDidMount() {
    setTimeout(() => {
      this.setState({ lpAnimation: true })
    }, 100)
  }

  handlePageChange = async (destination) => {
    let number = destination
    console.log("Event : ", number)
    if (number >= 0 && number <= 3) {
      const sideButtons = [...this.state.SideNavButtons];
      await this.setAnimations(number);
      await this.setState({ currentPage: number });
      { number >= 1 ? this.setState({ ActiveClass: "transparent-dark" }) : this.setState({ ActiveClass: "dark" }) }
      await sideButtons.forEach(c => {
        if (number !== c._id) {
          sideButtons[c._id].isActive = false;
          this.setState({ SideNavButtons: sideButtons });
        }
        sideButtons[number].isActive = true;
      })
      await this.setState({ SideNavButtons: sideButtons });
    } else if (number === 4) {
      await this.setState({ currentPage: number });
      this.setState({ mdAnim: false })
    }
  }
  setAnimations = (id) => {
    { id === 1 ? this.setState({ showAnimation: true }) : this.setState({ showAnimation: false }) }
    { id === 3 ? this.setState({ mdAnim: true }) : this.setState({ mdAnim: false }) }
    {
      id === 0 ? setTimeout(() => {
        this.setState({
          lpAnimation: true
        })
      }, 200) : this.setState({ lpAnimation: false })
    }
  }

  render() {
    const { SideNavButtons, currentPage, showAnimation, lpAnimation, mdAnim } = this.state;

    return (
      <div>
        <Router>
          <div className="">

            <NavBar ActiveClass={this.state.ActiveClass}></NavBar>
            <SideNav SideNavButtons={SideNavButtons} currentPage={this.state.currentPage} OnNavButtonClick={this.handlePageChange} />
            <ReactFullpage showAnimation={showAnimation} lpAnimation={lpAnimation}
              mdAnim={mdAnim} currentPage={currentPage}
              handlePageChange={this.handlePageChange} />
          </div>

        </Router>
      </div>
    );
  }
}

export default App;
