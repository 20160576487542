import React, { Component } from "react";
import "./trailer.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import SS0 from "../assets/images/trailer_layer1.jpg";
import SS2 from "../assets/images/Trailer_Image.png";
import SS3 from "../assets/images/screenshot3.jpg";
import Play from "../assets/images/play.png";
import TrailerBackground from "../assets/images/trailer.jpg";
import Modals from "../components/modal";
import Parallax from "parallax-js";

class TrailerPage extends Component {
  state = {
    ShowModal: false,
    showAnimation: false,
  };

  componentDidMount() {
    let scene = this.refs.scene;
    let parallaxInstance = new Parallax(scene);
  }
  render() {
    const { showAnimation } = this.props;
    const PlayVideo = () => {
      this.setState({ ShowModal: true });
    };
    const closeVideo = () => {
      this.setState({ ShowModal: false });
    };

    return (
      <div id="Trailer" ref="TrailerRef" className={"TrailerContainer"}>
        <div ref="scene" data-pointer-events="true">
          <div className="Overlay"></div>
          <img src={SS0} alt="No pic found" className="Layer1" />
          <div className="row" style={{width: "100%"}}>
            <div className="col">
              <div className={"trailer_3rdlayer"}>
                <div className={"textTitle " + (showAnimation ? "image-animation-down" : "")}>Biped Motion Control</div>
                <div className="Layer4" data-pointer-events="true">
                  <img className="Layer4img1" src={TrailerBackground} onClick={PlayVideo} ></img>
                  <div className="playbtn">
                    <img src={Play} onClick={PlayVideo} ></img>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className={"image-container"}>
                <img src={SS2} className={(showAnimation ? "image-animation-right" : "")} alt="No pic found" />
              </div>
            </div>
          </div>
          {/* <img src={SS2} className={this.props.showAnimation ? "Side_image_Trailer":"Side_image_Trailer-empty"} alt="No pic found" />
          <div className={this.props.showAnimation ? "trailer_3rdlayer" : "trailer_3rdlayer-empty"}><h1>Biped Motion Control</h1></div> */}
        </div>

        {this.state.ShowModal ? (
          <Modals show={this.state.ShowModal} unshow={closeVideo} Source="https://www.youtube.com/embed/gdViR-R1rUQ"  playlist="gdViR-R1rUQ"></Modals>
        ) : (
            ""
          )}
      </div>
    );
  }
}

export default TrailerPage;


