import React, { Component } from 'react';
import './mooding.css'
import SquareBoxes from '../../components/squarebox'
import Modals from '../../components/modal';
class Mooding extends Component {
    state = {
        boxData: [
            { id: '0', key: '0', heading: "Watch Gameplay 1", details: "FOURTHMOTION Blog..",playlist: "OuAKP_XkeqU", videoSource: "https://youtube.com/embed/OuAKP_XkeqU" },
            { id: '1', key: '1', heading: "Watch Gameplay 2", details: "FOURTHMOTION Blog..",playlist: "s19ybz3oMKQ", videoSource: "https://youtube.com/embed/s19ybz3oMKQ" },
            { id: '2', key: '2', heading: "Watch Gameplay 3", details: "FOURTHMOTION Blog..",playlist: "RGtJx30VaMM", videoSource: "https://youtube.com/embed/RGtJx30VaMM" },
            { id: '3', key: '3', heading: "Watch Gameplay 4", details: "FOURTHMOTION Blog..",playlist: "ut_1JUByh4s", videoSource: "https://youtube.com/embed/ut_1JUByh4s" },
            { id: '4', key: '4', heading: "Watch Gameplay 5", details: "FOURTHMOTION Blog..",playlist: "Kpe10ORupTg", videoSource: "https://youtube.com/embed/Kpe10ORupTg" },
            { id: '5', key: '5', heading: "Watch Gameplay 6", details: "FOURTHMOTION Blog..",playlist: "BBP_Qe3ksus", videoSource: "https://youtube.com/embed/BBP_Qe3ksus" },
            { id: '6', key: '6', heading: "Watch Gameplay 7", details: "FOURTHMOTION Blog..",playlist: "tibbBYUqWcU", videoSource: "https://youtube.com/embed/tibbBYUqWcU" },
            { id: '7', key: '7', heading: "Watch Gameplay 8", details: "FOURTHMOTION Blog..",playlist: "8BWdBCA4S04", videoSource: "https://youtube.com/embed/8BWdBCA4S04" },
        ],
        ShowModal: false,
        playSourceId: null,
    }

    src="https://youtube.com/embed/8BWdBCA4S04?loop=1&autoplay=1&controls=1&showinfo=0&mute=1&playlist=8BWdBCA4S04"

    render() {
        const { mdAnim } = this.props;
        const playVideoModal = (id) => {
            console.log("clicked", id)
            this.setState({ ShowModal: true, playSourceId: id });
        }
        const closeVideo = () => {
            this.setState({ ShowModal: false });

            console.log("Unshow Called");
        };
        return (
            <div id="Mooding" className="Mooding-main">

                <div className={"center-box " + (mdAnim ? "mooding-animation-up" : "")}>
                    {this.state.boxData.map((data, index) => {
                        return <SquareBoxes heading={data.heading} details={data.details} playVideoModal={() => playVideoModal(index)} />
                    })}
                </div>

                {this.state.ShowModal ? (
                    <Modals show={this.state.ShowModal} unshow={closeVideo} Source={this.state.boxData[this.state.playSourceId].videoSource}  playlist={this.state.boxData[this.state.playSourceId].playlist} ></Modals>
                ) : ("")}
            </div>
        )
    }

}

export default Mooding;