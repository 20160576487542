import React, { Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import NavLogo from '../assets/images/NavbarLogo.png'
import Youtube from '../assets/images/youtube.png'
import Twitter from '../assets/images/twitter.png'
import Nav from "react-bootstrap/Nav";
import "./navbar.css";

class NavBar extends Component {
  render() { 
    return (
      <div>
        <Navbar
          bg={this.props.ActiveClass}
          variant="dark"
          expand="lg"
          className="navbar-main"
        >
          <Navbar.Brand href="#home">
            <img src={NavLogo} alt="NavLogo" style={{height:"35px"}}/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="#home">
            <img src={Youtube} alt="NavLogo" style={{height:"35px"}} className="social-links"/>
              </Nav.Link>
              <Nav.Link href="#home">
            <img src={Twitter} alt="NavLogo" style={{height:"35px"}} className="social-links" />
              </Nav.Link>
              {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">
                  Something
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated link
                </NavDropdown.Item>
              </NavDropdown> */}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

export default NavBar;
