import React, { Component,useState } from 'react';
import {Modal,Button} from 'react-bootstrap'
import './modal.css'

const Modals=(props)=> {
    const [show, setShow] = useState(true);
    
    return (
      <div>
  
        <Modal
          show={show}
          onHide={() => props.unshow()}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          centered = "true"
          size="lg"

        >

{/* 
<video autoPlay    style={{width: "722px", height: "406px", left: "0px", top: "-406px"}} >
  <source  src="https://www.youtube.com/embed/wq8EXq4elis"></source>
</video> */}

            
          <iframe  height="410px" width="720px" src={props.Source+"?loop=1&autoplay=1&controls=1&showinfo=0&mute=1&playlist="+props.playlist} alt="Video Not Found" 
          frameborder="0" 
          allow="accelerometer;  encrypted-media; gyroscope; picture-in-picture" 
          allowfullscreen></iframe>
        </Modal>
      </div>
    );
  }
  
  export default Modals;