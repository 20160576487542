import React, { Component } from 'react';
import ArrowLinks from './arrowlinks';


class SideNav extends Component {

  state = {
    nextPage: null,
    prevPage: null,
  }

  render() {
    const {SideNavButtons, currentPage} = this.props
    return (

      <div className="side-navigation">
        {SideNavButtons.map(c => {
          if (c.isActive && c._id >= 1) {
            return (
              <div className={c.isActive ? "Nav-Up-Arrow" : ""}>
                <span onClick={() => this.props.OnNavButtonClick(c._id - 1)}></span>
              </div>
            )
          }
        })}
        {SideNavButtons.map((btns, id) => {
          return (
            <ArrowLinks
              btnText={btns.isActive && (currentPage != 4) ?  btns.text : ""}
              className="sidenavButton"
              OnNavButtonClick={() => this.props.OnNavButtonClick(id)}
            />
          );
        })}

        {SideNavButtons.map(c => {
          if (c.isActive && c._id <= 2) {
            return (
              <div className={c.isActive ? "Nav-down-Arrow" : ""}>
                <span onClick={() => this.props.OnNavButtonClick(c._id + 1)}></span>
              </div>
            )
          }
        })}
      </div>



    );
  }
}


export default SideNav;