import React from "react";
import "./footer.css";
import arrowIcon from "../../assets/images/iconfinder-icon.svg"

const Footer = (props) => {
  return (
    <div id="Footer" className="footer-main">
      <div className="footer-container">
        <div className="footer-row1">
          <div className="footer-socialbtns">
            <a> <img src="https://www.freepnglogos.com/uploads/facebook-icons/facebook-icon-transparent-background-3.png" /></a>
            <img src="https://webstockreview.net/images/twitter-icon-white-png-3.png" />
            <img src="https://cdn2.iconfinder.com/data/icons/social-icons-33/128/Youtube-512.png" />
          </div>
          <div className="scrolltoTopbtn">
            <img src={arrowIcon} className="arrowIcon" onClick={() => props.handlePageChange(0)} />
          </div>
        </div>
 
        <hr></hr>


        <div className="footer-row3">
          <span>

            Fourth motion LLC
            </span>
        </div>

        <div className="footer-row4">
          <div className="left-items">
            <ul>
              <li><a>Terms of Services</a> </li>
              <li><a>Privacy Policy</a></li>
            </ul>
          </div>
          
        </div>

      </div>
    </div>
  );
};

export default Footer;
