import React, { Component } from 'react';

import LandingPage from '../pages/landingpage'
import TrailerPage from '../pages/trailer'
import Screenshots from '../pages/screebshots/screenshots'
import Mooding from '../pages/mooding/mooding'
import Footer from '../pages/footer/footer'
import ReactPageScroller from '../../node_modules/react-page-scroller/es'

class ReactFullPage extends Component {
  state = {
  };
  render() {
    const { showAnimation, lpAnimation, mdAnim, currentPage, handlePageChange } = this.props;
    return (
      <ReactPageScroller
        pageOnChange={handlePageChange}
        customPageNumber={currentPage}
        animationTimer={600}
        animationTimerBuffer={0}
        renderAllPagesOnFirstRender
        transitionTimingFunction = {"ease-in"}
      >
        <LandingPage lpAnimation={lpAnimation} handlePageChange={handlePageChange} />
        <TrailerPage showAnimation={showAnimation} ></TrailerPage>
        <Screenshots />
        <Mooding mdAnim={mdAnim} />
        <Footer handlePageChange={handlePageChange} />
      </ReactPageScroller>
    );
  }
}

export default ReactFullPage;